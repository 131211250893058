import "./index.css";
import PersonIcon from "@mui/icons-material/Person";
import HandymanIcon from "@mui/icons-material/Handyman";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import GroupIcon from "@mui/icons-material/Group";
import CampaignSharpIcon from "@mui/icons-material/CampaignSharp";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useDispatch, useSelector } from "react-redux";
import { logout, setUser } from "../../redux/actions/userAction";
import { useLocation, useNavigate } from "react-router-dom";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AirplayIcon from "@mui/icons-material/Airplay";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const User = useSelector(({ User }) => User);
  const { sidebarOpen } = User;

  const onLogout = () => {
    dispatch(logout({ navigate }));
  };

  const toggleSidebar = () => {
    dispatch(
      setUser({
        name: "sidebarOpen",
        value: !sidebarOpen,
      })
    );
  };

  return (
    <div className={`sidebar-container ${sidebarOpen ? "open" : "close"}`}>
      <div className="sidebar-header-title">
        <span>
          <CurrencyExchangeIcon />
        </span>
        Coinatik
        <span className="menu-icon-container" onClick={toggleSidebar}>
          <MenuOpenIcon />
        </span>
      </div>
      <div className="sidebar-items-container">
        <div
          className={`sidebar-item ${pathname === "/profile" && "selected"}`}
          onClick={() => navigate("/profile")}
        >
          <span>
            <PersonIcon />
          </span>
          Profile
        </div>
        <div
          className={`sidebar-item ${pathname === "/wallets" && "selected"}`}
          onClick={() => navigate("/wallets")}
        >
          <span>
            <AccountBalanceWalletIcon />
          </span>
          Wallets
        </div>
        <div
          className={`sidebar-item ${pathname === "/swaps" && "selected"}`}
          onClick={() => navigate("/swaps")}
        >
          <span>
            <SwapHorizIcon />
          </span>
          Swap
        </div>
        <div
          className={`sidebar-item ${pathname === "/users" && "selected"}`}
          onClick={() => navigate("/users")}
        >
          <span>
            <GroupIcon />
          </span>
          Users
        </div>
        <div
          className={`sidebar-item ${
            pathname === "/announcements" && "selected"
          }`}
          onClick={() => navigate("/announcements")}
        >
          <span>
            <CampaignSharpIcon />
          </span>
          Announcements
        </div>
        <div
          className={`sidebar-item ${pathname === "/orders" && "selected"}`}
          onClick={() => navigate("/orders")}
        >
          <span>
            <StorefrontIcon />
          </span>
          Orders
        </div>
        <div
          className={`sidebar-item ${pathname === "/referrals" && "selected"}`}
          onClick={() => navigate("/referrals")}
        >
          <span>
            <PeopleOutlineIcon />
          </span>
          Referrals
        </div>
        <div
          className={`sidebar-item ${pathname === "/config" && "selected"}`}
          onClick={() => navigate("/config")}
        >
          <span>
            <HandymanIcon />
          </span>
          Configuration
        </div>
        <div
          className={`sidebar-item ${pathname === "/settings" && "selected"}`}
          onClick={() => navigate("/settings")}
        >
          <span>
            <SettingsIcon />
          </span>
          Settings
        </div>
        <div
          className={`sidebar-item ${pathname === "/miners" && "selected"}`}
          onClick={() => navigate("/miners")}
        >
          <span>
            <AirplayIcon />
          </span>
          Miners
        </div>
        <div className="sidebar-item" onClick={onLogout}>
          <span>
            <PowerSettingsNewIcon />
          </span>
          Log Out
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
