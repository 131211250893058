import { useEffect, useState } from "react";
import Axios from "axios";
import { BASE_URL } from "../../redux/actions/types";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Input,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import withDashboard from "../../HOC/withDashboard";

const AlertsList = () => {
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [newAlert, setNewAlert] = useState({
    category: "",
    description: "",
    reference: "",
    url: "",
    image: null,
  });

  const fetchAlerts = async () => {
    try {
      const { data } = await Axios.get(`${BASE_URL}/api/alert/get_alerts`, {
        withCredentials: true,
      });
      setAlerts(data?.data);
    } catch (error) {
      console.error("Error fetching alerts", error);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const handleToggleActive = async (id, isActive) => {
    try {
      await Axios.patch(
        `${BASE_URL}/api/alert/update_global_alert/${id}`,
        { is_active: isActive },
        { withCredentials: true }
      );
      setAlerts((prevAlerts) =>
        prevAlerts.map((alert) =>
          alert?.alertDetails?._id === id
            ? {
                ...alert,
                alertDetails: { ...alert.alertDetails, is_active: isActive },
              }
            : alert
        )
      );
    } catch (error) {
      console.error("Error updating alert status", error);
    }
  };

  const handleChange = (e) => {
    setNewAlert({ ...newAlert, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setNewAlert({ ...newAlert, image: e.target.files[0] });
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("category", newAlert.category);
      formData.append("description", newAlert.description);
      formData.append("reference", newAlert.reference);
      formData.append("url", newAlert.url);
      if (newAlert.image) {
        formData.append("image", newAlert.image);
      }
      await Axios.post(`${BASE_URL}/api/alert/send_alert`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });
      fetchAlerts();
      setOpen(false);
      setNewAlert({
        category: "",
        description: "",
        reference: "",
        url: "",
        image: null,
      });
    } catch (error) {
      console.error("Error creating alert", error);
    }
  };

  return (
    <div>
      <div className="mb-2 header">
        <Typography variant="h5">Alerts</Typography>
        <Button
          variant="contained"
          className="create-alert-btn btn-primary bg-slate-800"
          onClick={() => setOpen(true)}
          startIcon={<AddIcon />}
        >
          Create Alert
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Total Users</TableCell>
              <TableCell>Read</TableCell>
              <TableCell>Unread</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.length > 0 ? (
              alerts.map((alert) => (
                <TableRow key={alert._id}>
                  <TableCell>{alert.alertDetails.category}</TableCell>
                  <TableCell>{alert.alertDetails.description}</TableCell>
                  <TableCell>
                    {alert.alertDetails.url ? (
                      <a
                        href={alert.alertDetails.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {alert.alertDetails.image ? (
                      <img
                        src={`${alert.alertDetails.image}`}
                        alt="Alert"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      "No Image"
                    )}
                  </TableCell>
                  <TableCell>{alert.totalUsers}</TableCell>
                  <TableCell>{alert.readUsers}</TableCell>
                  <TableCell>{alert.unreadUsers}</TableCell>
                  <TableCell>
                    {new Date(alert.alertDetails.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={alert?.alertDetails?.is_active}
                      onChange={(e) =>
                        handleToggleActive(
                          alert?.alertDetails?._id,
                          e.target.checked
                        )
                      }
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No alerts available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create Alert</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Category"
            name="category"
            value={newAlert.category}
            onChange={handleChange}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={newAlert.description}
            onChange={handleChange}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Reference"
            name="reference"
            value={newAlert.reference}
            onChange={handleChange}
            margin="dense"
          />
          <TextField
            fullWidth
            label="URL"
            name="url"
            value={newAlert.url}
            onChange={handleChange}
            margin="dense"
          />
          <Input type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className="bg-blue-500 btn-primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withDashboard(AlertsList);
